import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as config from "../../../config/config";
import { updateRecentSearches } from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateJobAlertCreateDialog,
  updateJobAlertCreateDialogWithKeyword,
  updateVisitorJobAlertDialogDetails,
  updateVisitorJobAlertDialogOrigin,
} from "../../../redux/actions/dialog_action";
import {
  createJobAlert,
  deleteJobAlert,
  getJobAlerts,
  updateJobAlertEmail,
  updateShowJobAlertDialog,
} from "../../../redux/actions/job_action";
import {
  hideSnackBar,
  showSnackBar,
} from "../../../redux/actions/snackbar_action";
import { getUser } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import {
  BoxStyled,
  CheckCircleIconStyled,
  CloseIconStyled,
  ContentContainer,
  IconButtonStyled,
  ImageStyled,
  InputBaseAdornmentStyled,
  InputBaseStyled,
  JobAlertContainer,
  LabelContainer,
  LinkStyled,
  NoResultsContainer,
  NoResultsTextContainer,
  NotificationsIconStyled,
  PaperPlaneIconStyled,
  PaperStyled,
  SearchTipsContainer,
  SetAlertButtonStyled,
  SubtitleStyled,
  TitleStyled,
  ToggleText,
} from "./styles";

function JobAlertToggle(props) {
  const checkJobAlertExists = (keyword) => {
    if (!keyword) return;

    return store.getState().jobs.jobAlerts.some(function (alert) {
      if (
        alert.title == keyword.toString().toLowerCase().trim() ||
        alert.title?.toString().toLowerCase().trim() ==
          keyword.toString().toLowerCase().trim()
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  let keyword = props.jobListFilter?.keyword ?? "";
  const reduxKeywordHook = useSelector(
    (state) => state.jobs.jobListFilter.keyword
  );
  const fetchingJobs = useSelector((state) => state.jobs.fetchingAllJobs);
  const allJobs = useSelector((state) => state.jobs.jobs);
  const { noSearchResults } = props;
  const [toggle, setToggle] = useState(checkJobAlertExists(keyword));
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const cAlert = urlParams.get("title");
  const user = getUser();
  const screenDesktop = useMediaQuery("(min-width:1280px)");
  const dispatch = useDispatch();
  const router = useRouter();
  const localStorageUser = getUser();

  useEffect(() => {
    if (props?.jobListFilter?.keyword?.constructor === Array) {
      keyword = props.jobListFilter?.keyword[0];
    } else {
      keyword = props.jobListFilter?.keyword;
    }

    if (keyword?.trim()?.length > 0) {
      props.getJobAlerts();
    }

    if (cAlert && cAlert == keyword) {
      setToggle(true);
    }
  }, []);

  useEffect(() => {
    // Sync job alert and set as selected
    const keyword = props.jobListFilter?.keyword;
    if (checkJobAlertExists(keyword)) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [props.jobAlerts]);

  useEffect(() => {
    // Only for visitor
    if (!user && keyword) {
      if (props.showJobAlertVisitorEmailDialog || props.show_signin_modal) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    }
  }, [props.showJobAlertVisitorEmailDialog, props.show_signin_modal]);

  const handleSearchSuggestionClicked = () => {
    let cKeyword;

    if (keyword.constructor === Array) {
      cKeyword = keyword[0];
    } else {
      cKeyword = keyword;
    }

    // For GA tracking
    if (store.getState().jobs.fromHomepageSearchbar) {
      sendTrackingEvent({
        event: "CE_search-main-job-autocorrect",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${props.searchSuggestion}`,
      });
      store.getState().jobs.fromHomepageSearchbar = false;
    } else if (store.getState().jobs.fromNavSearchbar) {
      sendTrackingEvent({
        event: "CE_search-job-autocorrect-nav-job-list",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${props.searchSuggestion}`,
      });
      store.getState().jobs.fromNavSearchbar = false;
    } else if (store.getState().jobs.fromJobsListSearchbar) {
      sendTrackingEvent({
        event: "CE_search-job-autocorrect-job-list",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${props.searchSuggestion}`,
      });
      store.getState().jobs.fromJobsListSearchbar = false;
    } else if (store.getState().jobs.fromJobsListNavBar) {
      sendTrackingEvent({
        event: "CE_search-job-autocorrect-nav-job-list",
        "previous-search-term": `${cKeyword}`,
        "search-term": `${props.searchSuggestion}`,
      });
      store.getState().jobs.fromJobsListNavBar = false;
    }

    store.getState().jobs.jobListFilter["keyword"] = props.searchSuggestion;
    if (props.loadingJobs) {
      props.loadingJobs(true);
    }
    updateRecentSearches(props.searchSuggestion);
  };

  const handleCloseBanner = () => {
    sendTrackingEvent({
      event: "CE_close-job-alert-banner",
    });
    props.setBannerVisible(false);
  };

  const handleEmailInputChange = (event) => {
    dispatch(
      updateVisitorJobAlertDialogDetails({
        email: event.target.value,
      })
    );
    props.updateJobAlertEmail(event.currentTarget.value);
  };

  const handleSetVisitorAlert = () => {
    dispatch(
      updateVisitorJobAlertDialogDetails({
        keyword: reduxKeywordHook,
      })
    );
    dispatch(updateVisitorJobAlertDialogOrigin("job-alert-banner"));
    dispatch(updateShowJobAlertDialog(true));
  };

  const renderJobAlertToggle = () => {
    // For no search result, only render when not fetching and empty job list
    if (noSearchResults && !(allJobs.length === 0 && !fetchingJobs)) {
      return false;
    }
    return true;
  };

  return renderJobAlertToggle() ? (
    <Fragment>
      <Fragment>
        {noSearchResults ? (
          <NoResultsContainer>
            <ImageStyled
              src={
                config.assetDomain +
                "/images/jobseeker-illustrations/hiredly-job-search-empty-placeholder.svg"
              }
            />
            <NoResultsTextContainer>
              <TitleStyled>
                No results found for <span>{keyword}</span>
              </TitleStyled>
              {props.searchSuggestion?.length > 0 &&
              keyword !== props.searchSuggestion ? (
                <TitleStyled>
                  Did you mean:{" "}
                  <LinkStyled onClick={handleSearchSuggestionClicked}>
                    {props.searchSuggestion}
                  </LinkStyled>
                </TitleStyled>
              ) : (
                <SearchTipsContainer>
                  <SubtitleStyled>
                    <span>Search Tips:</span>
                  </SubtitleStyled>
                  <ul>
                    <li>Ensure that the search terms are spelled correctly</li>
                    <li>Try different search terms</li>
                    <li>Adjust your filters to widen your search</li>
                  </ul>
                </SearchTipsContainer>
              )}
            </NoResultsTextContainer>
          </NoResultsContainer>
        ) : (
          <JobAlertContainer
            id={props.jobAlertBannerId}
            $checked={toggle ? "true" : "false"}
          >
            <ContentContainer>
              <LabelContainer>
                {!toggle ? (
                  <NotificationsIconStyled />
                ) : (
                  <CheckCircleIconStyled />
                )}
                <ToggleText $logged_in={localStorageUser ? "true" : "false"}>
                  {toggle
                    ? "Job Alert Created"
                    : keyword && keyword?.toString().trim()?.length > 0
                    ? "Send me matches for this search"
                    : "Email me new jobs"}
                </ToggleText>
              </LabelContainer>
              <BoxStyled>
                {localStorageUser ? (
                  <SetAlertButtonStyled
                    varia="contained"
                    onClick={() => {
                      return toggle
                        ? router.push("/profile#manage-job-alerts")
                        : dispatch(
                            updateJobAlertCreateDialogWithKeyword({
                              keyword: reduxKeywordHook,
                              origin: "job-alert-banner",
                            })
                          ).then(() =>
                            dispatch(updateJobAlertCreateDialog(true))
                          );
                    }}
                  >
                    {toggle ? "Manage Job Alert" : "Set Alert"}
                  </SetAlertButtonStyled>
                ) : (
                  <PaperStyled>
                    <InputBaseStyled
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSetVisitorAlert();
                        }
                      }}
                      placeholder={
                        screenDesktop
                          ? "Your email address"
                          : "Enter email for job updates"
                      }
                      onChange={handleEmailInputChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handleSetVisitorAlert}
                          >
                            <InputBaseAdornmentStyled>
                              {screenDesktop ? (
                                "Set Alert"
                              ) : (
                                <PaperPlaneIconStyled />
                              )}
                            </InputBaseAdornmentStyled>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </PaperStyled>
                )}

                <IconButtonStyled
                  aria-label="Close"
                  size="small"
                  onClick={handleCloseBanner}
                >
                  <CloseIconStyled />
                </IconButtonStyled>
              </BoxStyled>
            </ContentContainer>
          </JobAlertContainer>
        )}
      </Fragment>
    </Fragment>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    jobListFilter: state.jobs.jobListFilter,
    jobAlerts: state.jobs.jobAlerts,
    showJobAlertToggle: state.jobs.showJobAlertToggle,
    showJobAlertVisitorEmailDialog: state.dialog.showJobAlertVisitorEmailDialog,
    show_signin_modal: state.navbar.showSignInModal,
    searchSuggestion: state.jobs.searchSuggestion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideSnackBar: bindActionCreators(hideSnackBar, dispatch),
    showSnackBar: bindActionCreators(showSnackBar, dispatch),
    createJobAlert: bindActionCreators(createJobAlert, dispatch),
    getJobAlerts: bindActionCreators(getJobAlerts, dispatch),
    deleteJobAlert: bindActionCreators(deleteJobAlert, dispatch),

    updateJobAlertEmail: bindActionCreators(updateJobAlertEmail, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAlertToggle);
