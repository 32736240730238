import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, BoxProps, Button, Typography } from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

export const SpecializationContainer = styling(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "0.3rem",
});

interface JobSpecializationProps extends BoxProps {
  show: boolean;
}

export const JobSpecializationContainer = styling(Box)(
  (props: JobSpecializationProps) => ({
    display: props.show === true ? "inline-block" : "none",
  })
);

export const JobSpecializationMenuContainer = styling(Box)(
  (props: JobSpecializationProps) => ({
    display: props.show === true ? "inline-block" : "none",
  })
);

export const MenuListContainer = styling(Box)({
  display: "flex",
  flexDirection: "column",
});

export const MenuContainer = styling(Box)({
  display: "flex",
  justifyContent: "space-between",

  borderRadius: "0.5rem",

  width: "100%",
  padding: "0.5rem 0.25rem 0.5rem 1rem",

  transition: "all 0.125s ease-in-out",

  "&:hover": {
    backgroundColor: Color.lightHiredlyPurple,
    cursor: "pointer",
  },
});

export const MenuBoxStyled = styling(Box)({
  display: "flex",
});

export const TextStyled = styling(Typography)({
  fontSize: FontSize.desktopCaption,
});

export const NumberedStyled = styling(Box)({
  fontSize: FontSize.desktopCaption,
  width: "1.5rem",
  height: "1.5rem",

  display: "flex",
  justifyContent: "center",
  alignItems: "baseline",

  backgroundColor: Color.black,
  color: Color.white,

  padding: "0.05rem 0",
  borderRadius: "5rem",
});

export const TopContainer = styling(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const SelectAllButton = styling(Button)({
  fontWeight: "700",
  textTransform: "capitalize",
  color: Color.hiredlyPurple,
});

export const BoxContainer = styling(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
});

export const ButtonStyled = styling(Button)({
  minWidth: "2rem",
  width: "2rem",
  height: "2rem",

  padding: "0",

  borderRadius: "0.5rem",
});

export const RightArrowIcon = styling(ChevronRight)({});

export const LeftArrowIcon = styling(ChevronLeft)({});
