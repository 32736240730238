import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectAllJobFilter } from "../../../redux/actions/job_action";
import {
  ApplyButton,
  CancelApplyButtonContainer,
  CancelButton,
  CheckStyled,
  CheckboxStyled,
  CheckedIcon,
  FilterItemText,
  JobSearchOptionsMenuBox,
  MenuItemStyled,
  SelectAllButton,
  SelectAllGrid,
  UncheckedIcon,
} from "./styles";

function JobSearchOptionsMenu(props) {
  const {
    id,
    filterArray,
    type,
    onTriggerLoadJob,
    checkboxValueChange,
    isFilterSelected,
    getFilter,
    getFilterValue,
    handleCancelClick,
  } = props;

  let trackIds = [];
  let states = [];
  let jobTypesVar = [];
  let experienceLevelsVar = [];

  const dispatch = useDispatch();
  const jobListFilter = useSelector((state) => state?.jobs?.jobListFilter);

  const selectedFilters = getFilter(jobListFilter, type);

  const getAllTrackIds = () => {
    let x = 0;

    while (x < filterArray?.length) {
      trackIds.push(parseInt(filterArray[x]?.id));
      x++;
    }
  };

  const getAllStates = () => {
    let x = 0;

    while (x < filterArray?.length) {
      states.push(filterArray[x]?.state);
      x++;
    }
  };

  const getAllJobTypes = () => {
    let x = 0;

    while (x < filterArray?.length) {
      jobTypesVar.push(parseInt(filterArray[x]?.id)); // Id's need to be parsed/converted to integers
      x++;
    }
  };

  const getAllExperienceLevels = () => {
    let x = 0;

    while (x < filterArray?.length) {
      experienceLevelsVar.push(parseInt(filterArray[x]?.id)); // Id's need to be parsed/converted to integers
      x++;
    }
  };

  const handleSelectAll = () => {
    if (type === "track") {
      getAllTrackIds();

      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["trackIds"] = trackIds;

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    } else if (type === "state") {
      getAllStates();

      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["stateRegionNames"] = states;

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    } else if (type === "job-type") {
      getAllJobTypes();

      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["jobTypeIds"] = jobTypesVar;

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    } else {
      getAllExperienceLevels();

      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["experienceIds"] = experienceLevelsVar;

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    }
  };

  const handleDeselectAll = () => {
    if (type === "track") {
      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["trackIds"] = [];

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    } else if (type === "state") {
      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["stateRegionNames"] = [];

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    } else if (type === "job-type") {
      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["jobTypeIds"] = [];

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    } else {
      let cJobListFilter = { ...jobListFilter };

      cJobListFilter["experienceIds"] = [];

      dispatch(updateSelectAllJobFilter(cJobListFilter));
    }
  };

  const renderSelectAndDeselectAllButton = (type) => {
    if (type === "track") {
      if (
        !jobListFilter["trackIds"] ||
        jobListFilter["trackIds"]?.length <= 0
      ) {
        return (
          <SelectAllButton onClick={handleSelectAll} variant="text">
            Select All
          </SelectAllButton>
        );
      } else {
        return (
          <SelectAllButton onClick={handleDeselectAll} variant="text">
            Deselect All
          </SelectAllButton>
        );
      }
    } else if (type === "state") {
      if (
        !jobListFilter["stateRegionNames"] ||
        jobListFilter["stateRegionNames"]?.length <= 0
      ) {
        return (
          <SelectAllButton onClick={handleSelectAll} variant="text">
            Select All
          </SelectAllButton>
        );
      } else {
        return (
          <SelectAllButton onClick={handleDeselectAll} variant="text">
            Deselect All
          </SelectAllButton>
        );
      }
    } else if (type === "job-type") {
      if (
        !jobListFilter["jobTypeIds"] ||
        jobListFilter["jobTypeIds"]?.length <= 0
      ) {
        return (
          <SelectAllButton onClick={handleSelectAll} variant="text">
            Select All
          </SelectAllButton>
        );
      } else {
        return (
          <SelectAllButton onClick={handleDeselectAll} variant="text">
            Deselect All
          </SelectAllButton>
        );
      }
    } else {
      if (
        !jobListFilter["experienceIds"] ||
        jobListFilter["experienceIds"]?.length <= 0
      ) {
        return (
          <SelectAllButton onClick={handleSelectAll} variant="text">
            Select All
          </SelectAllButton>
        );
      } else {
        return (
          <SelectAllButton onClick={handleDeselectAll} variant="text">
            Deselect All
          </SelectAllButton>
        );
      }
    }
  };

  return (
    <JobSearchOptionsMenuBox id={id}>
      <SelectAllGrid container justifyContent="flex-end">
        {renderSelectAndDeselectAllButton(type)}
      </SelectAllGrid>

      {filterArray.map((filterItem, index) => (
        <MenuItemStyled key={index}>
          <FormControl required component="fieldset">
            <FormControlLabel
              control={
                <CheckboxStyled
                  icon={<UncheckedIcon />}
                  onChange={checkboxValueChange(type, filterItem)}
                  name={filterItem.title || filterItem.state}
                  checked={isFilterSelected(
                    selectedFilters,
                    getFilterValue(type, filterItem)
                  )}
                  checkedIcon={
                    <CheckedIcon>
                      <CheckStyled />
                    </CheckedIcon>
                  }
                />
              }
              label={
                <FilterItemText>
                  {filterItem.title || filterItem.state}
                </FilterItemText>
              }
            />
          </FormControl>
        </MenuItemStyled>
      ))}
      <CancelApplyButtonContainer container justifyContent="space-around">
        <CancelButton
          onClick={() => handleCancelClick(type)}
          variant="outlined"
        >
          Cancel
        </CancelButton>
        <ApplyButton onClick={onTriggerLoadJob(type)} variant="contained">
          Apply
        </ApplyButton>
      </CancelApplyButtonContainer>
    </JobSearchOptionsMenuBox>
  );
}

export default JobSearchOptionsMenu;
