import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ApplyButton,
  CancelApplyButtonContainer,
  CancelButton,
} from "../JobSearchOptions/styles";
import JobSpecializationMenu from "./JobSpecializationMenu";
import {
  BoxContainer,
  JobSpecializationContainer,
  MenuBoxStyled,
  MenuContainer,
  NumberedStyled,
  RightArrowIcon,
  SelectAllButton,
  SpecializationContainer,
  TextStyled,
} from "./styles";

function JobSpecialization(props: any) {
  const {
    handleCancelClick,
    checkboxValueChange,
    getFilter,
    onTriggerLoadJob,
    updateSelectAllJobFilter,
  } = props;

  const [show, setShow] = useState(false);
  const [currentSpecialization, setCurrentSpecialization] = useState(null);

  const jobListFilter = useSelector((state: any) => state?.jobs?.jobListFilter);
  const specializationList = useSelector(
    (state: any) => state?.jobs?.specialisation
  );

  const type = "specialisation";

  const selectedFilters = getFilter(jobListFilter, type);

  function openSubMenu(index: any) {
    setShow(true);
    setCurrentSpecialization(index);
  }

  function closeSubMenu() {
    setShow(false);
  }

  return (
    <SpecializationContainer>
      <JobSpecializationContainer show={!show}>
        <BoxContainer>
          <RenderButton
            jobListFilter={jobListFilter}
            specializationList={specializationList}
            selectedFilters={selectedFilters}
            updateSelectAllJobFilter={updateSelectAllJobFilter}
          />
        </BoxContainer>
        {specializationList?.map((filterItem: any, index: any) => {
          return (
            <MenuContainer onClick={() => openSubMenu(index)}>
              <TextStyled>{filterItem.name}</TextStyled>
              <MenuBoxStyled>
                {jobListFilter?.trackIds?.length > 0 && (
                  <SelectedSpecialization
                    jobListFilter={jobListFilter?.trackIds}
                    filterArray={specializationList[index]?.subSpecialisations}
                  />
                )}
                <RightArrowIcon />
              </MenuBoxStyled>
            </MenuContainer>
          );
        })}
      </JobSpecializationContainer>
      <JobSpecializationMenu
        type={type}
        jobListFilter={jobListFilter}
        filterArray={
          specializationList[currentSpecialization]?.subSpecialisations
        }
        checkboxValueChange={checkboxValueChange}
        selectedFilters={selectedFilters}
        updateSelectAllJobFilter={updateSelectAllJobFilter}
        closeSubMenu={closeSubMenu}
        show={show}
      />
      <CancelApplyButtonContainer container justifyContent="space-around">
        <CancelButton
          onClick={() => handleCancelClick(type)}
          variant="outlined"
        >
          Cancel
        </CancelButton>
        <ApplyButton onClick={onTriggerLoadJob(type)}>Apply</ApplyButton>
      </CancelApplyButtonContainer>
    </SpecializationContainer>
  );
}

function SelectedSpecialization(props: any) {
  const { jobListFilter, filterArray } = props;

  const count = jobListFilter?.filter((num: any) =>
    filterArray?.some((obj: any) => obj?.id === num)
  )?.length;

  return count > 0 ? <NumberedStyled>{count}</NumberedStyled> : null;
}

//render select all and deselect all button
function RenderButton(props: any) {
  const {
    specializationList,
    selectedFilters,
    updateSelectAllJobFilter,
    jobListFilter,
  } = props;

  let specializationIds = [];

  function handleSelectAll() {
    let cJobListFilter = { ...jobListFilter };
    const fullSpecList = specializationList?.flatMap((item: any) =>
      item?.subSpecialisations?.map((subItem: any) => ({ id: subItem.id }))
    );

    fullSpecList?.forEach((obj: any) => {
      specializationIds.push(obj.id);
    });

    cJobListFilter["trackIds"] = specializationIds;

    updateSelectAllJobFilter(cJobListFilter);
  }

  function handleDeselectAll() {
    let cJobListFilter = { ...jobListFilter };
    cJobListFilter["trackIds"] = specializationIds;

    updateSelectAllJobFilter(cJobListFilter);
  }

  if (selectedFilters?.length > 0) {
    return (
      <SelectAllButton
        onClick={handleDeselectAll}
        variant="text"
        sx={{ justifyContent: "flex-end", padding: "0 1rem" }}
      >
        Deselect All
      </SelectAllButton>
    );
  } else {
    return (
      <SelectAllButton
        onClick={handleSelectAll}
        variant="text"
        sx={{ justifyContent: "flex-end", padding: "0 1rem" }}
      >
        Select All
      </SelectAllButton>
    );
  }
}

export default JobSpecialization;
