import Color from "../../../assets/colors";
import styled, { css } from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";

// Icon Component //
export const SearchIconStyled = styled(SearchIcon)`
  && {
    height: 0.8em;
    width: 0.8em;
    font-size: 1.45rem;
  }
`;

// Paper Component //
export const NavbarSearchStyled = withTheme(styled(Paper)`
  && {
    cursor: pointer;
    border-radius: 0.5rem;
    padding-left: 25px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 300px;
    max-width: 566px;
    height: 3rem;
    border: 1px solid ${Color.secondaryGrey};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);

    ${(props) =>
      props.$searchBarFocus &&
      css`
        border: 1px solid ${Color.hiredlyPurple};
      `}

    &:hover {
      border: 1px solid ${Color.hiredlyPurple};
    }

    ${(props) => props.theme.breakpoints.down("lg")} {
      height: 2.5rem;
      padding-left: 1rem;
    }
  }
`);

// Button Component //
export const IconButtonStyled = withTheme(styled(IconButton)`
  && {
    background-color: ${Color.hiredlyPurple};
    color: ${Color.white};
    margin: auto 0.5rem;
    border-radius: 0.5rem;
    height: 2.25rem;
    width: 2.25rem;

    &:hover {
      background-color: ${Color.black};
    }

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
`);

// Icon Button Component //
export const ClearIconButtonStyled = withTheme(styled(IconButton)`
  && {
    margin: auto 0.5rem;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;

    ${(props) => props.theme.breakpoints.between("tablet", "lg")} {
      margin: auto 0;
    }
  }
`);

// Input Component //
export const InputBaseStyled = withTheme(styled(InputBase)`
  && {
    margin: auto 0;
    width: 100%;
    display: none;

    ${(props) => props.theme.breakpoints.up("mobileL")} {
      display: block;
      width: ${(props) => (props.page == "job-list-page" ? "90%" : "74%")};
    }
  }
`);

// Grid components //
export const SearchDropdownContainer = styled(Grid)`
  && {
    position: absolute;
    top: 160px;
    z-index: 20;
    background-color: ${Color.white};
    width: 100%;
    max-width: 370px;
    border-radius: 10px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
    padding: 10px;
    margin-top: 5px;
  }
`;

export const SearchSuggestionContainer = styled(Grid)`
  && {
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 30px;
    margin-bottom: 5px;

    &:hover {
      background-color: ${Color.lightGrey};
      cursor: pointer;
    }
  }
`;
