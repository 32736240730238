import { ClickAwayListener } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Color from "../../../assets/colors";
import {
  calibrateSalary,
  convertExpectedSalary,
} from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  getExperienceLevels,
  getJobTypes,
  getJobs,
  getSpecialisation,
  getStateRegions,
  updateCheckingUrlParamForJobFilterStatus,
  updateInternshipFilterButtonStatus,
  updateJobListFilter,
  updateSelectAllJobFilter,
} from "../../../redux/actions/job_action";
import { updateShowSignInModalStatus } from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import SharedMobileFilterDialog from "../../shared/SharedMobileFilterDialog/SharedMobileFilterDialog";
import JobSpecialization from "../JobSpecialization/JobSpecialization";
import JobSearchOptionsMenu from "./JobSearchOptionsMenu";
import {
  AccordionCollapse,
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  ApplyButton,
  BadgeStyled,
  BetaTextStyled,
  BoxContainer,
  BoxStyled,
  ButtonStyled,
  CancelApplyButtonContainer,
  CancelButton,
  ContentTextStyled,
  CountContainer,
  DownArrowIcon,
  FilterCategory,
  FilterIcon,
  FilterLabel,
  FilterMenu,
  InfoIconStyled,
  InternshipIcon,
  JobPreferenceToggle,
  JobSearchOptionsMenuBox,
  LabelStyled,
  LinkStyled,
  MinSalaryWrapper,
  MobileButtonStyled,
  MobileToggleStyled,
  SliderContainer,
  SliderStyled,
  SnackbarTextStyled,
  ToggleStyled,
  TooltipStyled,
  TooltipTextStyled,
  useStyles,
} from "./styles";

function JobSearchOptions(props) {
  const {
    jobPreferenceToggle,
    setJobPreferenceToggle,
    numOfFilter,
    isJobSearch,
  } = props;

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filterMenuAnchorRef = useRef(null);
  const [filterMenuPosition, setFilterMenuPosition] = useState({ top: 0 });

  const [salary, setSalary] = useState(0);
  const [jobFilterIndex, setJobFilterIndex] = useState(null);

  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const open = Boolean(anchorEl);
  const classes = useStyles();
  const router = useRouter();

  const currentUser = useSelector((state) => state?.user?.user);

  const trackIds = useSelector((state) => state.jobs.jobsParams.trackIds);
  const stateRegions = useSelector(
    (state) => state.jobs.jobsParams.stateRegions
  );
  const jobTypesIds = useSelector((state) => state.jobs.jobsParams.jobTypeIds);
  const experienceIds = useSelector(
    (state) => state.jobs.jobsParams.experienceIds
  );
  const expectedSalary = useSelector(
    (state) => state.jobs.jobsParams.expectedSalary
  );

  const desktopView = useMediaQuery("(min-width:1280px)");
  const tabletView = useMediaQuery("(min-width:768px)");

  const onInternshipFilterClick = () => {
    let selectedFilters = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    if (props.internshipFilterButtonStatus) {
      props
        .updateJobListFilter((selectedFilters["jobTypeIds"] = []))
        .then((response) => {
          props.updateJobListFilter(selectedFilters);
          props.loadingJobs(true, "job-search-filters");
          props.updateInternshipFilterButtonStatus(false);
        });
      if (jobPreferenceToggle) {
        const userJobType = currentUser?.jobTypeIds;

        if (userJobType?.length > 0) {
          selectedFilters["jobTypeIds"] = [...userJobType];
          props.updateJobListFilter(selectedFilters);
          props.updateInternshipFilterButtonStatus(false);
        }
      }
    } else {
      props
        .updateJobListFilter((selectedFilters["jobTypeIds"] = [4]))
        .then((response) => {
          props.updateJobListFilter(selectedFilters);
          props.loadingJobs(true, "job-search-filters");
          props.updateInternshipFilterButtonStatus(true);
        });
      triggerSnackbarFunc({
        snackbarMessage: (
          <SnackbarTextStyled>
            Your job listing page has now been refreshed and displaying{" "}
            <b>Jobs for Interns.</b>
          </SnackbarTextStyled>
        ),
        severity: "success",
      });
    }

    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        "job-type": selectedFilters["jobTypeIds"],
      },
    });
  };

  const handleOpenFilters = (event) => {
    setAnchorEl(event.currentTarget);

    // getBoundingClientRect return the current position of element on the actual screen
    // usage: calculate menu position accurately to avoid overlapping
    const filterMenuAnchorPosition =
      filterMenuAnchorRef.current?.getBoundingClientRect();
    setFilterMenuPosition((prev) => ({
      ...prev,
      top: filterMenuAnchorPosition.top,
    }));
  };

  const handleCloseFilters = () => {
    setJobFilterIndex(null);
    setPanel("");
    setAnchorEl(null);
  };

  useEffect(() => {
    const specialization = store.getState().jobs.specialisation;
    const stateRegions = store.getState().jobs.stateRegions;
    const jobTypes = store.getState().jobs.jobTypes;
    const experienceLevels = store.getState().jobs.experienceLevels;

    // Only recall graphQL to populate chips if redux param value is empty

    if (specialization.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getSpecialisation(params);
    }

    if (stateRegions.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getStateRegions(params);
    }

    if (jobTypes.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getJobTypes(params);
    }

    if (experienceLevels.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getExperienceLevels(params);
    }
  }, []);

  const handleSearchParams = (query) => {
    const cJoblistFilter = store.getState().jobs.jobListFilter;

    //convert single string to array of string
    const cState = Array.isArray(query["state"])
      ? query["state"]
      : typeof query["state"] === "string" && [query["state"]];

    // convert array of strings to integers / convert single string to array of integer
    const cTrack = Array.isArray(query["specialisation"])
      ? query["specialisation"].map((str) => parseInt(str))
      : typeof query["specialisation"] === "string" && [
          parseInt(query["specialisation"]),
        ];

    const cExperience = Array.isArray(query["experience"])
      ? query["experience"].map((str) => parseInt(str))
      : typeof query["experience"] === "string" && [
          parseInt(query["experience"]),
        ];

    const cJobType = Array.isArray(query["job-type"])
      ? query["job-type"].map((str) => parseInt(str))
      : typeof query["job-type"] === "string" && [parseInt(query["job-type"])];

    const cSalary = Number(query["salary"]);

    //update job list filter
    if (query) {
      let needUpdate = false;
      if (query["state"]?.length > 0) {
        cJoblistFilter["stateRegionNames"] = cState;
        needUpdate = true;
      }
      if (query["specialisation"]?.length > 0) {
        cJoblistFilter["trackIds"] = cTrack;
        needUpdate = true;
      }
      if (query["job-type"]?.length > 0) {
        cJoblistFilter["jobTypeIds"] = cJobType;
        needUpdate = true;
      }
      if (query["experience"]?.length > 0) {
        cJoblistFilter["experienceIds"] = cExperience;
        needUpdate = true;
      }

      if (query["salary"]?.length > 0) {
        cJoblistFilter["expectedSalary"] = cSalary;
        needUpdate = true;
      }

      if (needUpdate) {
        props.updateJobListFilter(cJoblistFilter).then(() => {
          // update status to true so let filtered job list wrapper aware of it
          dispatch(updateCheckingUrlParamForJobFilterStatus(true));
        });
      } else {
        // update status to true so let filtered job list wrapper aware of it
        dispatch(updateCheckingUrlParamForJobFilterStatus(true));
      }
    }
  };

  //to get search params and trigger search
  useEffect(() => {
    // These checking are needed cause for some reason the first time router.query is loaded, the query param will not be returned, will only
    // available on the second trigger of the useEffect
    const asPath = router?.asPath;

    // only check when it is /job-search/ page
    // either 1 or 2 for number of filter based on page/route declaration
    const minFilterNum = numOfFilter ? numOfFilter : 1;
    if (
      asPath &&
      asPath.includes("?") &&
      asPath.includes("/job-search/") &&
      Object.keys(router.query).length <= minFilterNum
    )
      return;

    if (
      props.updateJobListFilterFunction &&
      Object.keys(router.query).length > 0
    ) {
      const query = router.query;

      handleSearchParams(query);

      // TEMPO SOLUTION:
      // only allow loading jobs if not job-search page
      // this solution wont affect other place
      // IMO: maybe not a good idea to load job here, shall only populate filter here and trigger load job at job listing wrapper
      if (!router.asPath.includes("/job-search/")) {
        props.loadingJobs(true, "job-search-filters");
      }
    }
  }, [router?.query]);

  const onPushRoute = (type) => {
    const ids =
      type == "specialisation"
        ? "trackIds"
        : type == "state"
        ? "stateRegionNames"
        : type == "job-type"
        ? "jobTypeIds"
        : "experienceIds";

    const salaryFilter = props.jobListFilter["expectedSalary"];

    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        [type]: props.jobListFilter[ids],
        salary: props.jobListFilter["expectedSalary"],
      },
    });
  };

  const onTriggerLoadJob = (type) => (e) => {
    // Close search menu
    switch (type) {
      case "salary": {
        setSalary(props.jobListFilter["expectedSalary"]);
        break;
      }
    }
    //update route after clicking apply
    onPushRoute(type);
  };

  const handleSliderChange = (event, newValue) => {
    props.updateJobListFilterFunction("expectedSalary", newValue);
  };

  const checkboxValueChange = (key, value) => (event) => {
    let cValue = value == -1 ? value : key == "state" ? value.state : value.id;

    if (key != "state" && value != -1) {
      try {
        cValue = parseInt(cValue);
      } catch (error) {
        console.error(error);
      }
    }

    if (props.updateJobListFilterFunction) {
      props.updateJobListFilterFunction(key, cValue);
    }
  };

  const getFilter = (jobListFilter, type) => {
    switch (type) {
      case "specialisation": {
        return jobListFilter["trackIds"];
      }
      case "state": {
        return jobListFilter["stateRegionNames"];
      }
      case "job-type": {
        return jobListFilter["jobTypeIds"];
      }
      case "experience": {
        return jobListFilter["experienceIds"];
      }
    }
  };

  // Make sure these variables are placed after getFilter function
  const expected_salary =
    props.jobListFilter && props.jobListFilter["expectedSalary"]
      ? props.jobListFilter["expectedSalary"]
      : 0;

  let specializationLength = getFilter(
    props.jobListFilter,
    "specialisation"
  )?.length;
  let stateLength = getFilter(props.jobListFilter, "state")?.length;
  let jobTypeLength = getFilter(props.jobListFilter, "job-type")?.length;
  let experienceLevelLength = getFilter(
    props.jobListFilter,
    "experience"
  )?.length;

  const totalFilter =
    (isNaN(specializationLength) ? 0 : specializationLength) +
    (isNaN(stateLength) ? 0 : stateLength) +
    (isNaN(jobTypeLength) ? 0 : jobTypeLength) +
    (isNaN(experienceLevelLength) ? 0 : experienceLevelLength) +
    (expected_salary === 0 || isNaN(expected_salary) ? 0 : 1);

  const getFilterValue = (type, filter) => {
    switch (type) {
      case "state": {
        return filter.state;
      }
      default: {
        return parseInt(filter.id);
      }
    }
  };

  const isFilterSelected = (selectedFilters, filter) => {
    if (filter == -1) {
      if (selectedFilters) {
        return selectedFilters.length == 0;
      } else {
        return true;
      }
    } else if (selectedFilters) {
      const index = selectedFilters?.indexOf(filter);
      return index != -1;
    }

    return false;
  };

  const handleAccordianClick = (index) => {
    if (index === jobFilterIndex) {
      setJobFilterIndex(null);
    } else {
      setJobFilterIndex(index);
    }
  };

  const handleCancelClick = (type) => {
    setPanel("");
  };

  // for job preference toggle

  useEffect(() => {
    if (checkAuthentication() && !isJobSearch) {
      getJobPreference();
    }
  }, [currentUser]);

  function checkLogin() {
    if (!props?.isSignedIn(true)) {
      if (!props?.isSignedIn(false)) {
      }
    } else {
      return true;
    }
  }

  function getJobPreference() {
    let selectedFilters = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    const userSpecialisation = currentUser?.trackIds;
    const userJobState = currentUser?.stateRegionNames;
    const userJobType = currentUser?.jobTypeIds;
    const userExperience = currentUser?.experienceIds;
    const userExpSalary = currentUser?.expectedSalary;

    if (
      userSpecialisation?.length > 0 ||
      userJobState?.length > 0 ||
      userJobType?.length > 0 ||
      userExperience?.length > 0 ||
      userExpSalary > 0
    ) {
      const filterRouteQuery = [];

      if (userSpecialisation?.length > 0) {
        selectedFilters["trackIds"] = [...userSpecialisation];
        filterRouteQuery["specialisation"] = [...userSpecialisation];
      }

      if (userJobState?.length > 0) {
        selectedFilters["stateRegionNames"] = [...userJobState];
        filterRouteQuery["state"] = [...userJobState];
      }

      if (userJobType?.length > 0) {
        selectedFilters["jobTypeIds"] = [...userJobType];
        filterRouteQuery["job-type"] = [...userJobType];
      }

      if (userExperience?.length > 0) {
        selectedFilters["experienceIds"] = [...userExperience];
        filterRouteQuery["experience"] = [...userExperience];
      }

      if (userExpSalary > 0) {
        selectedFilters["expectedSalary"] = [
          convertExpectedSalary(userExpSalary),
        ];
        filterRouteQuery["salary"] = [convertExpectedSalary(userExpSalary)];
      }

      // just need to update once here
      props.updateJobListFilter(selectedFilters);

      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          ...filterRouteQuery,
        },
      });

      setJobPreferenceToggle && setJobPreferenceToggle(true);
      return true;
    } else {
      setJobPreferenceToggle && setJobPreferenceToggle(false);
      return false;
    }
  }

  function clearJobPreference() {
    let selectedFilters = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    selectedFilters["trackIds"] = [];
    selectedFilters["stateRegionNames"] = [];
    selectedFilters["jobTypeIds"] = [];
    selectedFilters["experienceIds"] = [];
    selectedFilters["expectedSalary"] = 0;

    props.updateJobListFilter(selectedFilters);

    router.push({
      pathname: router.pathname,
    });

    if (props.internshipFilterButtonStatus) {
      selectedFilters["jobTypeIds"] = [4];
      props.updateJobListFilter(selectedFilters);

      router.push({
        pathname: router.pathname,
        query: {
          "job-type": selectedFilters["jobTypeIds"],
        },
      });
    }
    props.loadingJobs(true);
    handleJobPreferenceTracking();
    setJobPreferenceToggle(false);
  }

  function triggerSnackbar(jobPreferenceExist) {
    jobPreferenceExist
      ? (triggerSnackbarFunc({
          snackbarMessage: (
            <SnackbarTextStyled>
              Your job listing page has now been refreshed and displaying Job
              Titles according to your Job Preference.{" "}
              <LinkStyled href="/profile#job-preferences">
                Edit Job Preference?
              </LinkStyled>
            </SnackbarTextStyled>
          ),
          severity: "success",
        }),
        handleJobPreferenceTracking(),
        setJobPreferenceToggle(true))
      : triggerSnackbarFunc({
          snackbarMessage: (
            <SnackbarTextStyled>
              No job preferences set. Personalize your job recommendations in
              Settings.{" "}
              <LinkStyled href="/profile#job-preferences">
                Edit Job Preference?
              </LinkStyled>
            </SnackbarTextStyled>
          ),
          severity: "warning",
        });
  }

  function handleJobPreferenceTracking() {
    sendTrackingEvent({
      event: "CE_click-job-preference-toggle-jlp",
      action: jobPreferenceToggle ? "off" : "on",
    });
  }

  function handleJobPreferenceToggle(event) {
    event.target.checked
      ? checkLogin() && triggerSnackbar(getJobPreference())
      : clearJobPreference();
  }

  function handleTooltipClose() {
    setOpenTooltip(false);
  }

  function handleTooltipOpen() {
    setOpenTooltip(true);
  }

  function handlePopoverRedirect() {
    if (!checkAuthentication()) {
      return dispatch(updateShowSignInModalStatus(true));
    }
    router.push("/profile#job-preferences");
  }

  const [panel, setPanel] = useState("");

  const handleShow = (currentPanel) => {
    panel === currentPanel ? setPanel("") : setPanel(currentPanel);
  };

  return (
    <BoxStyled>
      {router.pathname.toString().includes("job-search") &&
      desktopView === false ? null : (
        <Fragment>
          {tabletView ? (
            desktopView ? (
              <Fragment>
                <ToggleStyled $active={jobPreferenceToggle}>
                  <FilterCategory>
                    <BetaTextStyled badgeContent={"BETA"}>
                      <LabelStyled variant="caption">
                        Job Preference
                      </LabelStyled>
                    </BetaTextStyled>
                  </FilterCategory>
                  <TooltipStyled
                    PopperProps={{
                      sx: {
                        margin: "-0.5rem 0 0 -2.1rem !important",
                      },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: "210px",
                          borderRadius: "0.5rem",
                          border: "2px solid",
                          color: Color.black,
                          backgroundColor: Color.white,
                          padding: "0.35rem",
                        },
                      },
                    }}
                    title={
                      <Fragment>
                        <TooltipTextStyled>
                          Activate this toggle to instantly filter jobs
                          according to your preferences.
                        </TooltipTextStyled>
                        <LinkStyled onClick={handlePopoverRedirect}>
                          Edit Your Preference? ✨
                        </LinkStyled>
                      </Fragment>
                    }
                  >
                    <InfoIconStyled />
                  </TooltipStyled>
                  <JobPreferenceToggle
                    checked={jobPreferenceToggle}
                    onChange={handleJobPreferenceToggle}
                  />
                </ToggleStyled>
                <BadgeStyled
                  badgeContent={totalFilter}
                  ref={filterMenuAnchorRef}
                  max={9}
                  color="primary"
                >
                  <ButtonStyled
                    aria-controls="job-filters-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    button_label="filter"
                    onClick={handleOpenFilters}
                    onClose={handleCloseFilters}
                    $job_filter_clicked={anchorEl}
                    $active={
                      trackIds?.length > 0 ||
                      stateRegions?.length > 0 ||
                      jobTypesIds?.length > 0 ||
                      experienceIds?.length > 0 ||
                      expectedSalary > 0
                        ? true
                        : false
                    }
                  >
                    <FilterCategory>
                      <FilterLabel variant="caption">filter</FilterLabel>
                    </FilterCategory>
                    <FilterIcon className="filter-icon" />
                  </ButtonStyled>
                </BadgeStyled>
                <ButtonStyled
                  aria-controls="job-internship-filter"
                  aria-haspopup="true"
                  variant="outlined"
                  button_label="internship"
                  onClick={onInternshipFilterClick}
                  $active={props.internshipFilterButtonStatus ? true : false}
                >
                  <FilterCategory>
                    <LabelStyled variant="caption">internship</LabelStyled>
                  </FilterCategory>
                  <InternshipIcon className="internship-icon" />
                </ButtonStyled>
              </Fragment>
            ) : (
              <Fragment>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <MobileToggleStyled $active={jobPreferenceToggle}>
                    <FilterCategory>
                      <BetaTextStyled badgeContent={"BETA"}>
                        <LabelStyled variant="caption">
                          Job Preference
                        </LabelStyled>
                      </BetaTextStyled>
                    </FilterCategory>

                    <TooltipStyled
                      open={openTooltip}
                      PopperProps={{
                        sx: {
                          margin: "-0.5rem 0 0 -2.1rem !important",
                        },
                      }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            width: "210px",
                            borderRadius: "0.5rem",
                            border: "2px solid",
                            color: Color.black,
                            backgroundColor: Color.white,
                            padding: "0.35rem",
                          },
                        },
                      }}
                      title={
                        <Fragment>
                          <TooltipTextStyled>
                            Activate this toggle to instantly filter jobs
                            according to your preferences.
                          </TooltipTextStyled>
                          <LinkStyled onClick={handlePopoverRedirect}>
                            Edit Your Preference? ✨
                          </LinkStyled>
                        </Fragment>
                      }
                    >
                      <InfoIconStyled onClick={handleTooltipOpen} />
                    </TooltipStyled>

                    <JobPreferenceToggle
                      checked={jobPreferenceToggle}
                      onChange={handleJobPreferenceToggle}
                    />
                  </MobileToggleStyled>
                </ClickAwayListener>
                <BadgeStyled
                  badgeContent={totalFilter}
                  ref={filterMenuAnchorRef}
                  max={9}
                  color="primary"
                >
                  <MobileButtonStyled
                    aria-controls="job-filters-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    button_label="filter"
                    onClick={handleOpenFilters}
                    onClose={handleCloseFilters}
                    $job_filter_clicked={anchorEl}
                    $active={
                      trackIds?.length > 0 ||
                      stateRegions?.length > 0 ||
                      jobTypesIds?.length > 0 ||
                      experienceIds?.length > 0 ||
                      expectedSalary > 0
                        ? true
                        : false
                    }
                  >
                    <FilterCategory>
                      <FilterLabel variant="caption">filter</FilterLabel>
                    </FilterCategory>
                    <FilterIcon className="filter-icon" />
                  </MobileButtonStyled>
                </BadgeStyled>
                <MobileButtonStyled
                  aria-controls="job-internship-filter"
                  aria-haspopup="true"
                  variant="outlined"
                  button_label="internship"
                  onClick={onInternshipFilterClick}
                  $active={props.internshipFilterButtonStatus ? true : false}
                >
                  <FilterCategory>
                    <LabelStyled variant="caption">internship</LabelStyled>
                  </FilterCategory>
                  <InternshipIcon className="internship-icon" />
                </MobileButtonStyled>
              </Fragment>
            )
          ) : (
            <Fragment>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <MobileToggleStyled $active={jobPreferenceToggle}>
                  <FilterCategory>
                    <BetaTextStyled badgeContent={"BETA"}>
                      <LabelStyled variant="caption">
                        Job Preference
                      </LabelStyled>
                    </BetaTextStyled>
                  </FilterCategory>

                  <TooltipStyled
                    open={openTooltip}
                    PopperProps={{
                      sx: {
                        margin: "-0.5rem 0 0 -2.1rem !important",
                      },
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: "210px",
                          borderRadius: "0.5rem",
                          border: "2px solid",
                          color: Color.black,
                          backgroundColor: Color.white,
                          padding: "0.35rem",
                        },
                      },
                    }}
                    title={
                      <Fragment>
                        <TooltipTextStyled>
                          Activate this toggle to instantly filter jobs
                          according to your preferences.
                        </TooltipTextStyled>
                        <LinkStyled onClick={handlePopoverRedirect}>
                          Edit Your Preference? ✨
                        </LinkStyled>
                      </Fragment>
                    }
                  >
                    <InfoIconStyled onClick={handleTooltipOpen} />
                  </TooltipStyled>

                  <JobPreferenceToggle
                    checked={jobPreferenceToggle}
                    onChange={handleJobPreferenceToggle}
                  />
                </MobileToggleStyled>
              </ClickAwayListener>
              <BadgeStyled
                badgeContent={totalFilter}
                ref={filterMenuAnchorRef}
                max={9}
                color="primary"
              >
                <MobileButtonStyled
                  aria-controls="job-filters-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  button_label="filter"
                  onClick={handleOpenFilters}
                  onClose={handleCloseFilters}
                  $job_filter_clicked={anchorEl}
                  $active={
                    trackIds?.length > 0 ||
                    stateRegions?.length > 0 ||
                    jobTypesIds?.length > 0 ||
                    experienceIds?.length > 0 ||
                    expectedSalary > 0
                      ? true
                      : false
                  }
                >
                  <FilterIcon className="filter-icon" />
                </MobileButtonStyled>
              </BadgeStyled>
              <MobileButtonStyled
                aria-controls="job-internship-filter"
                aria-haspopup="true"
                variant="outlined"
                button_label="internship"
                onClick={onInternshipFilterClick}
                $active={props.internshipFilterButtonStatus ? true : false}
              >
                <InternshipIcon className="internship-icon" />
              </MobileButtonStyled>
            </Fragment>
          )}
          <FilterMenu
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseFilters}
            className={classes.scrollBar}
            $position={filterMenuPosition}
            PaperProps={{
              style: {
                maxHeight: "78%",
              },
            }}
            unmountOnExit
          >
            {/* Specialisation Filter */}
            <AccordionContainer>
              <AccordionHeader
                selected={panel === "panel1"}
                onClick={() => handleShow("panel1")}
              >
                <BoxContainer gap={"10px"} alignItems={"center"}>
                  <LabelStyled $color="secondary_grey" variant="body2">
                    specialisation
                  </LabelStyled>
                  {specializationLength !== undefined &&
                  specializationLength > 0 ? (
                    <CountContainer>{specializationLength}</CountContainer>
                  ) : null}
                </BoxContainer>
                <DownArrowIcon selected={panel === "panel1"} />
              </AccordionHeader>
              <AccordionCollapse
                in={panel === "panel1"}
                timeout="auto"
                unmountOnExit
              >
                <AccordionContent
                  panel="panel1"
                  sx={{ padding: "1rem 0.5rem 0 0.5rem" }}
                >
                  <JobSpecialization
                    handleCancelClick={handleCancelClick}
                    checkboxValueChange={checkboxValueChange}
                    getFilter={getFilter}
                    onTriggerLoadJob={onTriggerLoadJob}
                    updateSelectAllJobFilter={props.updateSelectAllJobFilter}
                  />
                </AccordionContent>
              </AccordionCollapse>
            </AccordionContainer>

            {/* State Filter */}
            <AccordionContainer>
              <AccordionHeader
                selected={panel === "panel2"}
                onClick={() => handleShow("panel2")}
              >
                <BoxContainer gap={"10px"} alignItems={"center"}>
                  <LabelStyled $color="secondary_grey" variant="body2">
                    State / Region
                  </LabelStyled>
                  {stateLength !== undefined && stateLength > 0 ? (
                    <CountContainer>{stateLength}</CountContainer>
                  ) : null}
                </BoxContainer>
                <DownArrowIcon selected={panel === "panel2"} />
              </AccordionHeader>
              <AccordionCollapse
                in={panel === "panel2"}
                timeout="auto"
                unmountOnExit
              >
                <AccordionContent panel="panel2">
                  <JobSearchOptionsMenu
                    id={"job-state-menu"}
                    jobListFilter={props.jobListFilter}
                    type="state"
                    filterArray={props.stateRegions}
                    handleCancelClick={handleCancelClick}
                    checkboxValueChange={checkboxValueChange}
                    getFilter={getFilter}
                    getFilterValue={getFilterValue}
                    isFilterSelected={isFilterSelected}
                    onTriggerLoadJob={onTriggerLoadJob}
                    updateSelectAllJobFilter={props.updateSelectAllJobFilter}
                  />
                </AccordionContent>
              </AccordionCollapse>
            </AccordionContainer>

            {/* Job Types Filter */}
            <AccordionContainer>
              <AccordionHeader
                selected={panel === "panel3"}
                onClick={() => handleShow("panel3")}
              >
                <BoxContainer gap={"10px"} alignItems={"center"}>
                  <LabelStyled $color="secondary_grey" variant="body2">
                    job types
                  </LabelStyled>
                  {jobTypeLength !== undefined && jobTypeLength > 0 ? (
                    <CountContainer>{jobTypeLength}</CountContainer>
                  ) : null}
                </BoxContainer>
                <DownArrowIcon selected={panel === "panel3"} />
              </AccordionHeader>
              <AccordionCollapse
                in={panel === "panel3"}
                timeout="auto"
                unmountOnExit
              >
                <AccordionContent panel="panel3">
                  <JobSearchOptionsMenu
                    id={"job-type-menu"}
                    jobListFilter={props.jobListFilter}
                    type="job-type"
                    filterArray={props.jobTypes}
                    handleCancelClick={handleCancelClick}
                    checkboxValueChange={checkboxValueChange}
                    getFilter={getFilter}
                    getFilterValue={getFilterValue}
                    isFilterSelected={isFilterSelected}
                    onTriggerLoadJob={onTriggerLoadJob}
                    updateSelectAllJobFilter={props.updateSelectAllJobFilter}
                  />
                </AccordionContent>
              </AccordionCollapse>
            </AccordionContainer>

            {/* Experience Filter */}
            <AccordionContainer>
              <AccordionHeader
                selected={panel === "panel4"}
                onClick={() => handleShow("panel4")}
              >
                <BoxContainer gap={"10px"} alignItems={"center"}>
                  <LabelStyled $color="secondary_grey" variant="body2">
                    experience
                  </LabelStyled>
                  {experienceLevelLength !== undefined &&
                  experienceLevelLength > 0 ? (
                    <CountContainer>{experienceLevelLength}</CountContainer>
                  ) : null}
                </BoxContainer>
                <DownArrowIcon selected={panel === "panel4"} />
              </AccordionHeader>
              <AccordionCollapse
                in={panel === "panel4"}
                timeout="auto"
                unmountOnExit
              >
                <AccordionContent panel="panel4">
                  <JobSearchOptionsMenu
                    id={"experience-level-menu"}
                    jobListFilter={props.jobListFilter}
                    type="experience"
                    filterArray={props.experienceLevels}
                    handleCancelClick={handleCancelClick}
                    checkboxValueChange={checkboxValueChange}
                    getFilter={getFilter}
                    getFilterValue={getFilterValue}
                    isFilterSelected={isFilterSelected}
                    onTriggerLoadJob={onTriggerLoadJob}
                    updateSelectAllJobFilter={props.updateSelectAllJobFilter}
                  />
                </AccordionContent>
              </AccordionCollapse>
            </AccordionContainer>

            {/* Salary Filter */}
            <AccordionContainer>
              <AccordionHeader
                selected={panel === "panel5"}
                onClick={() => handleShow("panel5")}
              >
                <BoxContainer gap={"10px"} alignItems={"center"}>
                  <LabelStyled $color="secondary_grey" variant="body2">
                    salary
                  </LabelStyled>
                  {expected_salary !== undefined && expected_salary > 0 ? (
                    <CountContainer>
                      RM {calibrateSalary(expected_salary)}
                    </CountContainer>
                  ) : null}
                </BoxContainer>
                <DownArrowIcon selected={panel === "panel5"} />
              </AccordionHeader>
              <AccordionCollapse
                in={panel === "panel5"}
                timeout="auto"
                unmountOnExit
              >
                <AccordionContent panel="panel5">
                  <JobSearchOptionsMenuBox id="salary-menu">
                    <MinSalaryWrapper>
                      <ContentTextStyled>
                        minimum monthly salary
                      </ContentTextStyled>
                      <SliderContainer>
                        <SliderStyled
                          defaultValue={0}
                          aria-labelledby="salary-range-slider"
                          step={1}
                          marks
                          min={0}
                          max={50}
                          onChange={handleSliderChange}
                          value={expected_salary}
                        />
                      </SliderContainer>
                      <ContentTextStyled>
                        RM {calibrateSalary(expected_salary)}
                      </ContentTextStyled>
                    </MinSalaryWrapper>

                    <CancelApplyButtonContainer
                      container
                      justifyContent="space-around"
                    >
                      <CancelButton
                        onClick={() => handleCancelClick("salary")}
                        variant="outlined"
                      >
                        Cancel
                      </CancelButton>
                      <ApplyButton
                        onClick={onTriggerLoadJob("salary")}
                        variant="contained"
                      >
                        Apply
                      </ApplyButton>
                    </CancelApplyButtonContainer>
                  </JobSearchOptionsMenuBox>
                </AccordionContent>
              </AccordionCollapse>
            </AccordionContainer>
          </FilterMenu>
          <SharedMobileFilterDialog
            openFilterDialog={openMobileFilter}
            setOpenFilter={setOpenMobileFilter}
          />
        </Fragment>
      )}
    </BoxStyled>
  );
}

const mapStateToProps = (state) => {
  return {
    tracks: state.jobs.tracks,
    experienceLevels: state.jobs.experienceLevels,
    stateRegions: state.jobs.stateRegions,
    jobTypes: state.jobs.jobTypes,
    jobListFilter: state.jobs.jobListFilter,
    internshipFilterButtonStatus: state.jobs.internshipFilterButtonStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialisation: bindActionCreators(getSpecialisation, dispatch),
    getStateRegions: bindActionCreators(getStateRegions, dispatch),
    getJobTypes: bindActionCreators(getJobTypes, dispatch),
    getExperienceLevels: bindActionCreators(getExperienceLevels, dispatch),
    getJobs: bindActionCreators(getJobs, dispatch),
    updateJobListFilter: bindActionCreators(updateJobListFilter, dispatch),
    updateInternshipFilterButtonStatus: bindActionCreators(
      updateInternshipFilterButtonStatus,
      dispatch
    ),
    updateSelectAllJobFilter: bindActionCreators(
      updateSelectAllJobFilter,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSearchOptions);
