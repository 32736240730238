import BellIcon from "@heroicons/react/24/solid/BellIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Grid Components //
export const JobAlertContainer = withTheme(styled(Grid)`
  && {
    background: ${(props) =>
      props.$checked === "true"
        ? `linear-gradient(91.03deg, #170C39 -7.37%, #170C39 7.22%, #34226B 58.35%, #7237BE 87.03%, ${Color.warn} 107.34%)`
        : `linear-gradient(
        275.78deg,
        rgba(242, 175, 41, 0.4) -51.84%,
        rgba(242, 175, 41, 0.4) -29.88%,
        rgba(242, 125, 41, 0.7) -7.65%,
        rgba(81, 42, 204, 0.6) 31.08%,
        #35198d 100%
      )`};
    border: 2px solid ${Color.chipGrey};
    box-shadow: 2px 2px 0px ${Color.chipGrey};
    border-radius: 1rem;
    width: 100%;
    height: 4rem;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      max-width: 31.25rem;
    }
  }
`);

export const ContentContainer = styling(Grid)((props) => ({
  margin: "0 10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",

  [props.theme.breakpoints.down("lg")]: {
    margin: "0px",
    padding: "0 4px",
  },
}));

export const NoResultsContainer = styling(Grid)((props) => ({
  width: "100%",
  height: "calc(100vh - 180px)",
  padding: "0 2% 2% 2%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",

  [props.theme.breakpoints.down("mobileL")]: {
    padding: "0 5%",
    height: "85vh",
    background: Color.backgroundGrey,
    justifyContent: "center",
  },
}));

export const CardStyled = styling(Grid)((props) => ({
  padding: "8px 22px",
  borderRadius: "15px",
  background: "rgba(249, 249, 249, 1)",
  boxShadow: "10px 10px 25px rgb(181 181 181 / 60%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "400px",
  marginTop: "12px",

  [props.theme.breakpoints.down("mobileL")]: {
    width: "100%",
  },
}));

export const NoResultsTextContainer = styling(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const LabelContainer = styling(Grid)({
  display: "flex",
  alignItems: "center",
});

export const CustomDividerContainer = styling(Grid)({
  display: "flex",
  margin: "15px 0",
  alignItems: "center",
});

export const CustomDivider = styling(Box)((props) => ({
  width: "180px",
  borderBottom: `1px solid ${Color.secondaryGrey}`,

  [props.theme.breakpoints.down("md")]: {
    width: "140px",
  },
}));

export const SearchTipsContainer = styling(Grid)((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "left",
  padding: "15px 0 0 0",
  ul: {
    padding: "0 12px",
    margin: "0",
  },

  [props.theme.breakpoints.down("mobileL")]: {
    padding: "15px 25px 0 25px",
  },
}));

// Typography Components //
export const ToggleText = withTheme(styled(Typography)`
  && {
    color: ${Color.white};
    font-size: ${FontSize.desktopCaption};
    font-weight: ${(props) =>
      props.$subtitle === "true" ? FontSize.light : FontSize.bold};

    ${(props) => props.theme.breakpoints.down("lg")} {
      width: 12.5rem;
      max-width: 100%;
      margin-right: 0.625rem;
      display: ${(props) => (props.$logged_in === "true" ? "inline" : "none")};
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 8rem;
    }

    ${(props) => props.theme.breakpoints.down("mobile")} {
      width: 6.725rem;
    }
  }
`);

export const TitleStyled = styling(Typography)((props) => ({
  fontSize: FontSize.desktopBody,
  span: {
    fontWeight: FontSize.bold,
  },
  [props.theme.breakpoints.down("mobileL")]: {
    textAlign: "center",
    fontSize: FontSize.desktopBody,
  },
}));

export const SubtitleStyled = styling(Typography)((props) => ({
  fontSize: FontSize.desktopCaption,
  span: {
    fontWeight: FontSize.bold,
  },
}));

export const LabelDivider = styling(Typography)((props) => ({
  fontWeight: "bold",
  fontSize: "1rem",
  color: Color.secondaryGrey,
  margin: "0 10px",
}));

// Switch Components //
export const JobsListToggle = styling(Switch)((props) => ({
  root: {
    width: "43px",
    height: "24px",
    padding: "0",
    margin: "8px",
  },
  switchBase: {
    padding: "3px 3px 3px 5px",

    "&.checked": {
      transform: "translateX(15px)",
      "& + .track": {
        backgroundColor: Color.hiredlyPurple,
        opacity: "1",
        border: "none",
      },
    },
  },
  thumb: {
    width: "18px",
    height: "18px",
    color: props.checked ? Color.white : Color.black,
  },
  track: {
    borderRadius: "13px",
    backgroundColor: Color.white,
    opacity: "1",
  },
}));

export const NoResultsToggle = styling(Switch)((props) => ({
  root: {
    width: "43px",
    height: "24px",
    padding: "0",
    margin: "8px",
  },
  switchBase: {
    padding: "3px 3px 3px 5px",

    "&.checked": {
      transform: "translateX(15px)",
      "& + .track": {
        backgroundColor: Color.hiredlyPurple,
        opacity: "1",
        border: "none",
      },
    },
  },
  thumb: {
    width: "18px",
    height: "18px",
    color: Color.white,
  },
  track: {
    borderRadius: "13px",
    backgroundColor: Color.secondaryGrey,
    opacity: "1",
  },
}));

// Misc. Components //
export const ImageStyled = withTheme(styled.img`
  && {
    width: 270px;
    height: 270px;
  }
`);

export const NotificationsIconStyled = styling(BellIcon)((props) => ({
  color: Color.white,
  width: "30px",
  height: "30px",
  margin: "0 10px 0 8px",

  [props.theme.breakpoints.down("desktop")]: {
    width: "22px",
    height: "22px",
  },
}));

export const CheckCircleIconStyled = styling(CheckCircleIcon)({
  color: Color.white,
  width: "30px",
  height: "30px",
  margin: "0 10px 0 8px",
});

export const CloseIconStyled = styling(XMarkIcon)((props) => ({
  color: Color.white,
  width: "18px",
  height: "18px",
  marginLeft: ".3125rem",
  [props.theme.breakpoints.down("mobile")]: {
    marginLeft: "0",
  },
}));

export const PaperPlaneIconStyled = styling(PaperAirplaneIcon)({
  color: Color.white,
  width: "16px",
  height: "16px",
});

export const LinkStyled = styling(Link)({
  color: Color.hiredlyPurple,
  fontStyle: FontSize.italic,
  fontWeight: FontSize.bold,
  textDecoration: "underline",
  cursor: "pointer",
});

// Icon Button Component //
export const IconButtonStyled = styling(IconButton)((props) => ({
  [props.theme.breakpoints.down("lg")]: {
    marginRight: "8px",
  },
}));

// Box Components //
export const BoxStyled = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "right",

  [props.theme.breakpoints.down("lg")]: {
    justifyContent: "space-between",
    width: "100%",
  },

  [props.theme.breakpoints.down("lg")]: {
    justifyContent: "flex-end",
    width: "100%",
  },
}));

// Paper Component //
export const PaperStyled = styling(Paper)((props) => ({
  padding: "0px 5px",
  gap: "10px",
  border: `2px solid ${Color.black}`,
  boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  height: "36px",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",

  [props.theme.breakpoints.down("lg")]: {
    width: "100%",
    justifyContent: "space-between",
    padding: "0px 0px 0px 5px",
    gap: "0px",
  },
}));

// Input Component //
export const InputBaseStyled = styling(InputBase)((props) => ({
  fontSize: FontSize.desktopCaption,
  marginLeft: "5px",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    lineHeight: "15px",
    marginLeft: "0px",
    width: "100%",
  },
}));

export const SetAlertButtonStyled = styling(Button)((props) => ({
  background: Color.white,
  border: `2px solid ${Color.black}`,
  borderRadius: "8px",
  padding: "0px 16px",
  textTransform: "capitalize",
  color: Color.black,
  fontSize: FontSize.desktopCaption,
  lineHeight: "17px",
  height: "36px",

  "&:hover": {
    border: `2px solid ${Color.hiredlyPurple}`,
    background: Color.white,
    color: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    lineHeight: "15px",
    padding: "0px 10px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0px 6px",
  },
}));

export const VisitorSetAlertStyled = styling(Button)({
  background: Color.black,
  color: Color.white,
});

export const InputBaseAdornmentStyled = styling(Box)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "black",
  width: "80px",
  height: "26px",
  borderRadius: "4px",
  margin: "5px 3px 5px 0",
  fontSize: "14px",
  color: "white",
  letterSpacing: "0.15px",
  lineHeight: "24px",

  [props.theme.breakpoints.down("lg")]: {
    width: "38px",
    marginRight: "10px",
  },
}));
