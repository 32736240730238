import { FormControl, FormControlLabel } from "@mui/material";
import {
  CheckStyled,
  CheckboxStyled,
  CheckedIcon,
  FilterItemText,
  MenuItemStyled,
  UncheckedIcon,
} from "../JobSearchOptions/styles";
import {
  ButtonStyled,
  JobSpecializationMenuContainer,
  LeftArrowIcon,
  MenuListContainer,
  SelectAllButton,
  TopContainer,
} from "./styles";

function JobSpecializationMenu(props: any) {
  const {
    type,
    jobListFilter,
    filterArray,
    checkboxValueChange,
    selectedFilters,
    updateSelectAllJobFilter,
    closeSubMenu,
    show,
  } = props;

  let specializationIds = [];

  function handleSelectAll() {
    let cJobListFilter = { ...jobListFilter };

    filterArray?.forEach((obj: any) => {
      specializationIds.push(obj.id);
    });

    if (cJobListFilter?.trackIds?.length > 0) {
      cJobListFilter["trackIds"] = [
        ...cJobListFilter["trackIds"],
        ...specializationIds,
      ];
    } else {
      cJobListFilter["trackIds"] = specializationIds;
    }

    updateSelectAllJobFilter(cJobListFilter);
  }

  function handleDeselectAll() {
    let cJobListFilter = { ...jobListFilter };

    cJobListFilter["trackIds"] = cJobListFilter?.trackIds?.filter(
      (item: any) => {
        return !filterArray?.map((obj: any) => obj.id)?.includes(item);
      }
    );

    updateSelectAllJobFilter(cJobListFilter);
  }

  function isFilterSelected(selectedFilters: any, filter: any) {
    if (selectedFilters?.includes(filter)) {
      return true;
    }
    return false;
  }

  function renderSelectAndDeselectAllButton() {
    const cFilter = filterArray?.map((obj: any) => obj.id);

    if (!selectedFilters?.some((item: any) => cFilter?.includes(item))) {
      return (
        <SelectAllButton onClick={handleSelectAll} variant="text">
          Select All
        </SelectAllButton>
      );
    } else {
      return (
        <SelectAllButton onClick={handleDeselectAll} variant="text">
          Deselect All
        </SelectAllButton>
      );
    }
  }

  return (
    <JobSpecializationMenuContainer show={show}>
      <TopContainer>
        <ButtonStyled onClick={closeSubMenu}>
          <LeftArrowIcon />
        </ButtonStyled>
        {renderSelectAndDeselectAllButton()}
      </TopContainer>
      <MenuListContainer>
        {filterArray?.map((filterItem: any, index: any) => {
          return (
            <MenuItemStyled key={index}>
              <FormControl required component="fieldset">
                <FormControlLabel
                  control={
                    <CheckboxStyled
                      icon={<UncheckedIcon />}
                      onChange={checkboxValueChange("track", filterItem)}
                      checked={isFilterSelected(
                        selectedFilters,
                        filterItem?.id
                      )}
                      name={filterItem?.name}
                      checkedIcon={
                        <CheckedIcon>
                          <CheckStyled />
                        </CheckedIcon>
                      }
                    />
                  }
                  label={<FilterItemText>{filterItem?.name}</FilterItemText>}
                />
              </FormControl>
            </MenuItemStyled>
          );
        })}
      </MenuListContainer>
    </JobSpecializationMenuContainer>
  );
}

export default JobSpecializationMenu;
