import Clear from "@mui/icons-material/Clear";
import _ from "lodash";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  calibrateSalary,
  updateRecentSearches,
} from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateJobAlertCreateDialogWithKeyword,
  updateVisitorJobAlertDialogDetails,
} from "../../../redux/actions/dialog_action";
import {
  clearSearchSuggestions,
  fetchSearchSuggestions,
  updateJobAlertToggle,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import { store } from "../../../redux/stores/store";
import * as types from "../../../redux/types/job_type";
import {
  ClearIconButtonStyled,
  IconButtonStyled,
  InputBaseStyled,
  NavbarSearchStyled,
  SearchDropdownContainer,
  SearchIconStyled,
  SearchSuggestionContainer,
} from "./styles";

function SearchBar(props) {
  const [search, setSearch] = useState("");
  const [searchBarFocus, setSearchBarFocus] = useState(false);
  const textInputRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.jobListFilter?.keyword?.length > 0) {
      let params = {
        keyword: props.jobListFilter.keyword,
      };
      props.fetchSearchSuggestions(params);
    }

    textInputRef.current.value = props.jobListFilterLanding?.keyword
      ? props.jobListFilterLanding.keyword
      : props.jobListFilter?.keyword
      ? props.jobListFilter.keyword
      : "";
    setSearch(
      props.jobListFilterLanding?.keyword
        ? props.jobListFilterLanding.keyword
        : props.jobListFilter?.keyword
        ? props.jobListFilter.keyword
        : ""
    );

    return () => {
      store.getState().jobs.jobListFilter.keyword = "";
      store.getState().jobs.jobListFilterLanding.keyword = "";
    };
  }, []);

  useEffect(() => {
    if (props.autoCreatedAlert) {
      textInputRef.current.value = props.jobListFilter?.keyword
        ? props.jobListFilter.keyword
        : "";
      setSearch(
        props.jobListFilter?.keyword ? props.jobListFilter.keyword : ""
      );
      props.setAutoCreatedAlert(false);
    }
  }, [props.autoCreatedAlert]);

  const onSearchUpdate = (event) => {
    setSearch(event.target.value);
    store.getState().jobs.syncJobAlertToggle = false;

    if (props.updateJobListFilterFunction) {
      props.updateJobListFilterFunction(types.KEYWORD_KEY, event.target.value);
    }

    if (event.target.value.length > 2) {
      let params = {
        keyword: event.target.value,
      };
      // Improvement 1 - added debounce for this
      debouncedFetchSearchSuggestion(params);

      dispatch(
        updateVisitorJobAlertDialogDetails({
          keyword: event.target.value,
        })
      );
      props.updateJobAlertCreateDialogWithKeyword({
        keyword: event.target.value,
      });
    }
  };

  // Fetch search suggestion
  const fetchSearchSuggestion = _.debounce((params) => {
    dispatch(fetchSearchSuggestions(params));
  }, 500);
  const debouncedFetchSearchSuggestion = useCallback(
    (params) => fetchSearchSuggestion(params),
    []
  );
  // End fetch search suggestion

  const onSearchIconClicked = (origin) => {
    props.updateJobAlertToggle(true, true);
    if (props.loadingJobs) {
      props.loadingJobs(true);

      let cKeyword;

      if (props.jobListFilter?.keyword) {
        if (props.jobListFilter?.keyword?.constructor === Array) {
          cKeyword = props.jobListFilter?.keyword[0]?.trim();
        } else {
          cKeyword = props.jobListFilter?.keyword?.trim();
        }
      }

      dispatch(updateJobSearchKeyword({ keyword: cKeyword ?? "" }));

      let searchFilter = {
        keyword: cKeyword && cKeyword.length > 0 ? cKeyword : "",
        track:
          props.jobListFilter?.track_ids?.length > 0
            ? props.jobListFilter?.track_ids
            : [],
        state:
          props.jobListFilter?.state_region_names?.length > 0
            ? props.jobListFilter?.state_region_names
            : [],
        job_type:
          props.jobListFilter?.job_type_ids?.length > 0
            ? props.jobListFilter?.job_type_ids
            : [],
        experience_level:
          props.jobListFilter?.experience_ids?.length > 0
            ? props.jobListFilter?.experience_ids
            : [],
        min_monthly_salary: props.jobListFilter?.expected_salary
          ? calibrateSalary(props.jobListFilter?.expected_salary)
          : "0",
      };

      // data layer should be null if no filters are applied
      const checkFilter = (filters) => {
        if (
          searchFilter.track.length == 0 &&
          searchFilter.state.length == 0 &&
          searchFilter.job_type.length == 0 &&
          searchFilter.experience_level == 0 &&
          searchFilter.min_monthly_salary == 0
        ) {
          return null;
        } else {
          return filters;
        }
      };

      if (origin !== "suggested-search") {
        // Trigger custom event for keyword search on jobs page
        sendTrackingEvent({
          event: "CE_search-job-job-list",
          "search-term": `${cKeyword && cKeyword.length > 0 ? cKeyword : ""}`,
        });
      }

      store.getState().jobs.fromJobsListSearchbar = true;
      updateRecentSearches(search);
      setSearchBarFocus(false);
    }
    store.getState().jobs.fromJobsListSearchbar = true;

    props.clearSearchSuggestions();
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      dispatch(updateJobSearchKeyword({ keyword: event?.target?.value ?? "" }));
      onSearchIconClicked();
    }
  };

  const onSearchBarClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const searchOnClickListener = (e) => {
      if (event !== e) {
        setSearchBarFocus(false);
        window.removeEventListener("click", searchOnClickListener);
      }
    };

    if (!searchBarFocus) {
      setSearchBarFocus(true);
      window.addEventListener("click", searchOnClickListener);
    }
  };

  const handleSearchSuggestionClicked = (suggestion) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    updateRecentSearches(suggestion);
    textInputRef.current.value = suggestion;
    setSearch(suggestion);
    store.getState().jobs.jobListFilter["keyword"] = suggestion;
    onSearchIconClicked("suggested-search");
    setSearchBarFocus(false);
    sendTrackingEvent({
      event: "CE_search-job-suggestion-job-list",
      "search-term": suggestion,
    });
  };

  const boldMatchingText = (suggestion) => {
    const n = suggestion.toLowerCase();
    const q = props.jobListFilter["keyword"].toLowerCase();
    const x = n.indexOf(q);
    // if suggestion has no matching characters, return suggestion
    if (!q || x === -1) {
      return (
        <SearchSuggestionContainer
          onClick={handleSearchSuggestionClicked(suggestion)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: suggestion,
            }}
          />
        </SearchSuggestionContainer>
      );
    }
    // else bold characters that match search input
    const l = q.length;
    const boldedSearch =
      suggestion.substr(0, x) +
      "<b>" +
      suggestion.substr(x, l) +
      "</b>" +
      suggestion.substr(x + l);
    return (
      <SearchSuggestionContainer
        onClick={handleSearchSuggestionClicked(suggestion)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: boldedSearch,
          }}
        />
      </SearchSuggestionContainer>
    );
  };

  const handleClearSearchInput = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSearch("");
    textInputRef.current.value = "";
    store.getState().jobs.jobListFilter["keyword"] = "";
  };

  return (
    <Fragment>
      <NavbarSearchStyled
        component="div"
        elevation={0}
        page={props.page}
        $searchBarFocus={searchBarFocus}
      >
        <InputBaseStyled
          page={props.page}
          placeholder={"Search Jobs"}
          inputProps={{ "aria-label": "discover job search" }}
          onChange={onSearchUpdate}
          onKeyDown={onKeyDown}
          onClick={onSearchBarClicked}
          inputRef={textInputRef}
          value={props.jobListFilter.keyword}
        />
        {searchBarFocus && search.length > 2 && (
          <ClearIconButtonStyled onClick={handleClearSearchInput}>
            <Clear />
          </ClearIconButtonStyled>
        )}
        <IconButtonStyled
          size="small"
          aria-label="search"
          onClick={onSearchIconClicked}
        >
          <SearchIconStyled />
        </IconButtonStyled>
      </NavbarSearchStyled>
      {searchBarFocus ? (
        <Fragment>
          {search.length > 2 && props.searchData.length > 0 && (
            <SearchDropdownContainer>
              {props.searchData.map((data, index) => {
                return <div key={index}>{boldMatchingText(data.name)}</div>;
              })}
            </SearchDropdownContainer>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    jobListFilter: state.jobs.jobListFilter,
    searchData: state.jobs.searchData,
    jobListFilterLanding: state.jobs.jobListFilterLanding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobAlertToggle: bindActionCreators(updateJobAlertToggle, dispatch),
    fetchSearchSuggestions: bindActionCreators(
      fetchSearchSuggestions,
      dispatch
    ),
    clearSearchSuggestions: bindActionCreators(
      clearSearchSuggestions,
      dispatch
    ),
    updateJobAlertCreateDialogWithKeyword: bindActionCreators(
      updateJobAlertCreateDialogWithKeyword,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
