import Color from "../../../assets/colors";
import styled from "styled-components";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import PhoneInput from "react-phone-number-input";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// AutoComplete Component //
export const AutocompleteStyled = styling(Autocomplete)((props) => ({
  marginTop: "1rem",

  ".MuiOutlinedInput-root": {
    borderRadius: "0.5rem",

    fieldset: {
      border: `2px solid ${Color.secondaryGrey}`,
    },

    ":hover fieldset": {
      border: `2px solid ${Color.hiredlyPurple}`,
    },

    ".Mui-focused fieldset": {
      border: `2px solid ${Color.hiredlyPurple}`,
    },
  },
}));

// Textfield Component //
export const TextFieldStyled = styling(TextField)((props) => ({
  //
}));

// Phone Input Component //
export const PhoneInputStyled = withTheme(styled(PhoneInput)`
  && {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: ${(props) =>
      props.$error === "true"
        ? `2px solid ${Color.error}`
        : `2px solid ${Color.secondaryGrey}`};
    height: 3.5rem;
    padding: 0.75rem 1rem;

    &:hover {
      border-color: ${(props) =>
        props.$error === "true" ? Color.error : Color.hiredlyPurple};
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      & .PhoneInputInput {
        font-size: 0.875rem;
        border: none;
      }
    }

    ${(props) => props.theme.breakpoints.up("tablet")} {
      & .PhoneInputInput {
        font-size: 1rem;
        border: none;
      }
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      & .PhoneInputInput {
        flex: 1;
        background-color: ${Color.white};
      }
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      & .PhoneInputInput {
        flex: 1;
        background-color: ${Color.white};
        border: none;
      }
    }

    & .PhoneInputInput:focus {
      outline: none;
    }

    & .PhoneInputCountryIcon {
      width: calc(1em * 1.5);
      height: 1em;
    }

    & .PhoneInputCountryIcon--square {
      width: 1em;
    }

    & .PhoneInputCountryIconImg {
      display: block;
      width: 100%;
      height: 100%;
    }

    & .PhoneInputInternationalIconPhone {
      opacity: 0.8;
    }

    & .PhoneInputInternationalIconGlobe {
      opacity: 0.65;
    }

    & .PhoneInputCountry {
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      margin-right: 0.35em;
    }

    & .PhoneInputCountrySelect {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    & .PhoneInputCountrySelectArrow {
      display: block;
      content: "";
      width: 0.3em;
      height: 0.3em;
      margin-left: 0.35em;
      border-style: solid;
      border-color: inherit;
      border-top-width: 0;
      border-bottom-width: 1px;
      border-left-width: 0;
      border-right-width: 1px;
      transform: rotate(45deg);
      opacity: 0.45;
    }
  }
`);

// Divider Component //
export const DividerStyled = styling(Divider)((props) => ({
  margin: "1rem 0",
}));

// Box Components //
export const TitleContainer = styling(Box)((props) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",

  [props.theme.breakpoints.down(768)]: {
    alignItems: "start",
  },
}));

export const ButtonBox = styling(Box)((props) => ({
  textAlign: "center",
}));

export const SubtitleBox = styling(Box)((props) => ({
  width: "50ch",

  [props.theme.breakpoints.down(768)]: {
    width: "100%",
  },
}));

// Icon Button Component //
export const IconButtonStyled = styling(IconButton)((props) => ({
  //
}));

// Button Component //
export const SubmitButton = styling(Button)((props) => ({
  backgroundColor: Color.black,
  borderRadius: "2rem",
  color: Color.white,
  textTransform: "capitalize",
  fontWeight: "700",
  width: "6.25rem",
  height: "2.75rem",

  ":hover": {
    backgroundColor: Color.black,
  },
}));

// Dialog Component //
export const DialogStyled = styling(Dialog)((props) => ({
  ".MuiPaper-root": {
    padding: "1.25rem",
    border: `2px solid ${Color.black}`,
    borderRadius: "1.5rem",
  },

  ".MuiBackdrop-root": {
    backdropFilter: "blur(0.5rem)",
  },
}));

// Typography Component //
export const Title = styling(Typography)((props) => ({
  fontSize: "1.125rem",
  fontWeight: "700",
  marginBottom: "0.5rem",
}));

export const Subtitle = styling(Typography)((props) => ({
  color: Color.hiredlyPurple,
  fontSize: "0.875rem",
  fontWeight: "400",
}));

export const ErrorText = styling(Typography)((props) => ({
  color: Color.error,
  fontSize: "0.875rem",
  fontWeight: "400",
  marginTop: "0.25rem",
  marginLeft: "0.25rem",
}));

// Icon Components //
export const XMarkIconStyled = styling(XMarkIcon)((props) => ({
  width: "1.5rem",
  height: "1.5rem",
}));

export const ContentContainer = withTheme(styled(Box)`
  && {
    //

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      //
    }
  }
`);
